// Import images for regular collections
import collection1 from '../images/collection1.jpg';
import collection2 from '../images/collection2.jpg';
import collection3 from '../images/collection3.jpg';
import collection4 from '../images/collection4.jpg';
import collection5 from '../images/collection5.jpg';
import collection6 from '../images/collection6.jpg';
import collection7 from '../images/collection7.jpg';
import collection8 from '../images/collection8.jpg';
import collection9 from '../images/collection9.jpg';
import collection10 from '../images/collection10.jpg';

// Import images for au collections
import aucollection1 from '../images/aucollection1.jpg';
import aucollection2 from '../images/aucollection2.jpg';
import aucollection3 from '../images/aucollection3.jpg';
import aucollection4 from '../images/aucollection4.jpg';
import aucollection5 from '../images/aucollection5.jpg';
import aucollection6 from '../images/aucollection6.jpg';
import aucollection7 from '../images/aucollection7.jpg';
import aucollection8 from '../images/aucollection8.jpg';
import aucollection9 from '../images/aucollection9.jpg';
import aucollection10 from '../images/aucollection10.jpg';

// Import images for summer collections
import sucollection1 from '../images/sucollection1.jpg';
import sucollection2 from '../images/sucollection2.jpg';
import sucollection3 from '../images/sucollection3.jpg';
import sucollection4 from '../images/sucollection4.jpg';
import sucollection5 from '../images/sucollection5.jpg';
import sucollection6 from '../images/sucollection6.jpg';
import sucollection7 from '../images/sucollection7.jpg';
import sucollection8 from '../images/sucollection8.jpg';
import sucollection9 from '../images/sucollection9.jpg';
import sucollection10 from '../images/sucollection10.jpg';

// Data structure for collections categorized by type
const data = {
  regularCollections: [
    { id: 1, title: 'Squares', image: collection1, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 2, title: 'Ikat', image: collection2, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 3, title: 'Circles', image: collection3, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 4, title: 'Geometry blue', image: collection4, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 5, title: 'Tropical leaves', image: collection5, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 6, title: 'Bambi', image: collection6, description: 'Adorable Bambi-themed bedding.' },
    { id: 7, title: 'Byzantium', image: collection7, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 8, title: 'Evening Garden', image: collection8, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 9, title: 'Marquis\'s Feather', image: collection9, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 10, title: 'Lavender roses', image: collection10, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' }
  ],
  auCollections: [
    { id: 11, title: 'Safari', image: aucollection1, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 12, title: 'Multicolored Amethyst', image: aucollection2, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 13, title: 'Stucco ornament', image: aucollection3, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 14, title: 'Emerald (AB)', image: aucollection4, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 15, title: 'Malachite box', image: aucollection5, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 16, title: 'Scents of Spring', image: aucollection6, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 17, title: 'Autumn leaf fall (AB)', image: aucollection7, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 18, title: 'Blue Breeze (AB)', image: aucollection8, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 19, title: 'Water Blue (AB)', image: aucollection9, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' },
    { id: 20, title: 'Plaid', image: aucollection10, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make your bedrooms cozy and part of.' }
  ],
  suCollections: [
    { id: 1, title: 'Vintage Style', image: sucollection1, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make y' },
    { id: 2, title: 'Trembling Dandelion', image: sucollection2, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make y' },
    { id: 3, title: 'French Riviera', image: sucollection3, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make y' },
    { id: 4, title: 'Dolly the Sheep', image: sucollection4, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make y' },
    { id: 5, title: 'Alpine herbs', image: sucollection5, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make y' },
    { id: 6, title: 'Inspired', image: sucollection6, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make y' },
    { id: 7, title: 'Green Lotus', image: sucollection7, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make y' },
    { id: 8, title: 'Mountain cornflower', image: sucollection8, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make y' },
    { id: 9, title: 'Sakura branch', image: sucollection9, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make y' },
    { id: 10, title: 'Olive branch', image: sucollection10, description: 'Bedding is a household and decorative item for the bedroom, designed for comfort and beauty. You can choose from a variety of bedding sets that include sheets, blankets, pillowcases and other accessories. They have different patterns, prints, embroidery or other design elements. They are easy and quick to clean and wash. They are made of soft materials, and they make y' }
  ]
};

export default data;
