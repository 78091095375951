import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Aboutusimg from '../../images/aboutus.png';
import "./aboutus.scss"; 

export default function Aboutus() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  

  return (
    <div className="container aboutus-container">
      <div className="aboutus-text">
        <h1>{t("aboutUsTitle")}</h1> 
          <p>
            {t("aboutUsDescription1")}
          </p>
      </div>
      <div className="aboutus-image">
        <img
          src={Aboutusimg} 
          alt="Bukhara Natural Product Team"
        />
      </div>
    </div>
  );
}
